const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
     case 'WALLET_ACTION':
      return {
        ...state, ...action.payload
      }
      case 'WALLET_RESET':
      return defaultState;
      case 'WALLET_VALIDATE':
      return {
        ...state, ...action.payload
      }
     default:
      return state
    }
   }