import React, { Fragment } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import queryString from "query-string";
import ReactGA from "react-ga";
import LazyLoad from 'react-lazyload';

import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CmsPage from "./CmsPage";

import Appbar from "./Appbar";
import IndexPage from "./IndexPage";
import FindPage from "./FindPage";
import { OrderPage } from "./lib/order";
import { WidgetPage } from "./lib/widget";
import Footer from "./Footer";


import "./App.css";
import { Button, Typography } from "@material-ui/core";

if (window.location.hostname !== "localhost") {
  ReactGA.initialize("UA-146648282-1");
  ReactGA.ga('require', 'GTM-N352ZDT');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

window.addEventListener("message", function(e) {
  var message = e.data;
  if (message.type === "TRANSACTION_ISSUED") {
      ReactGA.event({
        category: 'Transaction',
        action: 'Buy BitCoin',
        value: message.cryptoAmount.toString() || ''
      });
  }
});

class App extends React.PureComponent {
  render() {
    // const { address } = this.state || {};
    return (
      <Fragment>
        <CssBaseline />
        <div className="App">
          <Router>
            <Appbar />
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/:id" component={CmsPage} />
            <Route exact path="/find" component={FindPage} />
            <Route exact path="/support" component={FindPage} />
            <Route exact path="/order" component={OrderPage} />
            <Route exact path="/external" component={WidgetPage} />
          </Router>
          <LazyLoad>
            <Footer />
          </LazyLoad>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.wallet
});

export default connect(mapStateToProps)(App);
