import React from 'react';
import { Button, Typography } from "@material-ui/core";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ReactGA from "react-ga";

const CardBanner = () => {

  return (
    <div style={{
      height: 375,
      width: '100vw',
      background: 'url(https://shop.blockfront.se/wp-content/uploads/2019/10/bitcoin-vardekort-1-e1573076449990.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: '68% center',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      padding: '6%',
      boxShadow: 'rgba(19, 23, 26, .6) 70px -100px 150px inset',
      color: '#fff'
    }}>
      <Typography variant={'h3'} align={'left'} gutterBottom>Bitcoin Värdekort</Typography> 
      <Typography variant={'h5'} align={'left'} gutterBottom style={{marginBottom: '1rem'}}>Köp framtidens pengar till dig själv, eller ge bort i present</Typography> 
      <a 
      href={'https://shop.blockfront.se/product/bitcoin-vardebarare-presentkort/?add-to-cart=10'} 
      target={'_blank'}
      rel="noopener"
      onClick={() => {
        ReactGA.event({
          category: 'User',
          action: 'Press shop link button'
        });
      }}
      >
      <Button
        size={'medium'} 
        variant={'outlined'} 
        color={'secondary'}
        >
        Lägg i varukorg &nbsp;<ExitIcon style={{fontSize: '18px'}} />
        </Button>
      </a>
    </div>
    
  );
}

export default CardBanner;