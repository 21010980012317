export const exchangeAction = () => dispatch => {
    //const url = 'https://api.coindesk.com/v1/bpi/currentprice/sek.json';

    //const url = `https://app.safello.com/v1/rate?currency=sek&crypto=btc&appId=330e9522-7ca6-4239-b075-3613514c9f3b`;
    const url = `https://blockchain.info/ticker`

    fetch(url).then(response => {
        response.json().then(res => {
            dispatch({
                type: 'EXCHANGE_ACTION',
                payload: {
                    ...res
                }
               })
        })
    })
}