import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Table, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import get from 'lodash/get';
import QRCode from 'qrcode'
import Favorites from './Favorites'

const generateQR = async text => {
    try {
        return await QRCode.toDataURL(text)
    } catch (err) {
        console.error(err)
    }
}

class Balance extends React.PureComponent {
    state = {
        wallet: null,
    }
    componentDidMount = () => {
        const qr = generateQR(this.props.wallet.address);
        qr.then(res => {
            this.setState({
                qr: res
            })
        })
    }

    render() {
        const { exchange, wallet } = this.props;
        const { qr } = this.state;

        if (!wallet || !wallet.code) return null;
        const rateFloat = get(exchange, 'SEK.last');
        const balance = parseFloat(wallet.balance * rateFloat);

        var formatter = new Intl.NumberFormat('sv-SE', {
            style: 'currency',
            currency: 'SEK',
            currencyDisplay: 'code',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

        const formattedValue = formatter.format(balance);

        return (
            <Fragment>
                <div style={{display: 'flex', marginBottom: '.4rem'}}>
                <Favorites address={wallet.address} s={wallet.serial}/>
                <div  style={{flex: 1}} />
                <Typography variant={'h5'} style={{margin: '0 1rem 0 0'}}>Saldo</Typography>
                </div>
                                
                <div style={{
                width: '100%', 
                background: '#eee',
                display: 'flex',
                height: 100,
                maxWidth: '100vw',
                }}>
                <div style={{
                    width: 100,
                    height: 100
                }}>
                    {qr && <img src={qr} height={'100%'} alt={'address'}></img>}
                </div>
                <div style={{flex: 1}}>
                <Table>
                    <TableBody>
                    <TableRow>
                        <TableCell style={{borderBottom: 'none', color: '#444', paddingRight: '20px'}} align={'right'}><Typography color="inherit" variant={'h5'} style={{fontSize: '1.3158rem'}}><strong>{wallet.balance} {wallet.currency}</strong></Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{borderBottom: 'none', color: '#444', paddingRight: '20px'}} align={'right'}><Typography variant={'h5'} color="inherit" style={{fontSize: '1.3158rem'}}><strong>{ formattedValue }</strong></Typography></TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
                </div>
            </div>
            </Fragment>
        );
    }
}

Balance.propTypes = {
    
};

function mapStateToProps(state, ownProps) {
    return {
        wallet: state.wallet,
        exchange: state.exchange
    };
}

export default connect(mapStateToProps)(withRouter(Balance));
