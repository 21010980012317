import React from 'react'

import {
    Typography,
} from '@material-ui/core';

const styles = {
    root: {
        width: 'calc(90vw)',
        maxWidth: '480px',
        minWidth: '320px',
        margin: '0 auto', 
    }
}
const appUrl = 'https://app.safello.com';
let params = {
    appId: process.env.APP_ID || '330e9522-7ca6-4239-b075-3613514c9f3b',
    border: false,
    lang: 'sv',
    country: 'se',
    crypto: 'btc',
    tabs: true
}

const OrderForm = () => {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let url = `${appUrl}/sdk/quickbuy.html?${queryString}`;

    return (
        <header className="App-header">
            <div style={styles.root}>
            <Typography variant={'h3'} gutterBottom>
                Köp / Sälj kryptovaluta
            </Typography>
            
            <iframe 
                title={'ladda-bitcoin'}
                src={url} 
                //width="400" 
                height="610px" 
                style={{
                    border: 'none', 
                    width: '100%',
                    background: '#fff',
                }}
                scrolling="yes"
                sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts allow-popups"
                >
                </iframe>
            </div>
        </header>
        
    
)
}



const WidgetPage = () => (<OrderForm/>);

export default OrderForm;

export {WidgetPage}