import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, TextField, Button, Grid, Paper } from "@material-ui/core";
import QRCode from "qrcode";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

import { collection, db } from "./firebase";
import AddressDialog from "./AddressDialog";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";

const values = {};

const styles = {
  root: {
    textAlign: "left",
    maxWidth: 600,
    margin: "1rem",
  },
};

const successColor = "rgb(20,162,20)";

const generateQR = async (text) => {
  try {
    return await QRCode.toDataURL(text);
  } catch (err) {
    console.error(err);
  }
};

class FindPage extends React.Component {
  state = {
    serial: "",
    code: "",
    docs: null,
    submitted: false,
    qr: "",
    dialogOpen: false,
  };

  handleSubmit = async () => {
    const valid = !!this.state.serial && !!this.state.code;

    console.log(this.state.serial);

    if (valid) {
      const docs = [];
      const q = query(collection(db, "cards"), where("serial", "==", this.state.serial), where("code", "==", this.state.code));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        docs.push(doc.data());
      });

      const doc = docs[0];

      if (doc) {
        const qr = generateQR("http://ladda.blockfront.se/?q=" + doc.address);
        qr.then((res) => {
          this.setState({
            qr: res,
          });
        });
      }

      this.setState({ dialogOpen: !!doc, submitted: true, docs: doc ? [doc] : [] });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ ...values, [name]: event.target.value });
  };

  render() {
    const { qr } = this.state;
    return (
      <div style={styles.root}>
        <Typography variant={"h4"} align={"center"} style={{ margin: "1rem" }}>
          Sök kort
        </Typography>
        <Typography variant={"body2"} gutterBottom align={"center"}>
          Hitta ditt kort med serienummer och kontrollkod som skrapas på baksidan.
        </Typography>
        <Paper style={{ padding: "1.5rem", margin: "1rem auto" }}>
          <Grid container spacing={2} margin={"normal"}>
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-serial"
                label="Serienummer"
                value={values.serial}
                onChange={this.handleChange("serial")}
                //margin="normal"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-code"
                label="Kontrollkod"
                value={values.code}
                onChange={this.handleChange("code")}
                //margin="normal"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={this.handleSubmit}
                style={{
                  //background: successColor,
                  //color: "#fff",
                  fontWeight: 600,
                }}
                size="large"
                margin="normal"
                color={"secondary"}
                fullWidth
              >
                <VerifiedUser />
                &nbsp;Verifiera äkthet
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {this.state.docs && (
          <div>
            <AddressDialog
              content={this.state.docs}
              onClose={() => {
                this.setState({ dialogOpen: false });
              }}
              open={this.state.dialogOpen}
            />
          </div>
        )}
        {this.state.submitted && this.state.docs && this.state.docs.length === 0 && <div style={{ marginTop: "4rem" }}>Inget resultat...</div>}
      </div>
    );
  }
}
/**

{this.state.docs.map((doc, i) => (
  <div key={i} style={{ margin: "2rem 0", textAlign: "center" }}>
    <p></p>
    <Typography variant={"h5"}>Din adress</Typography>
    <div
      style={{
        background: successColor,
        display: "inline-block",
        fontSize: "15px",
        padding: "1rem",
        marginBottom: "1rem"
      }}
    >
      <code>{doc.address}</code>
    </div>
    <br />
    {qr && <img src={qr} alt="" width={150} />}

    <br />
    <Link to={`/?q=${doc.address}`}>
      <Button
        variant={"contained"}
        size={"large"}
        color={"secondary"}
      >
        Ladda kort
      </Button>
    </Link>
  </div>
))}


*/

FindPage.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps)(FindPage);
