import React, { Component } from "react";
import {
  VictoryArea,
} from "victory";
import { withSize } from "react-sizeme";
import { Typography } from "@material-ui/core";

const apiUrl = "https://api.coindesk.com/v1/bpi/historical/close.json";

class Graph extends Component {
  state = {
    data: null
  };
  async componentDidMount() {
    const res = await fetch(apiUrl);
    const data = await res.json();

    this.setState({
      data: Object.keys(data.bpi).map(date => {
        return {
          date,
          price: data.bpi[date]
        };
      })
    });
  }

  render() {
    const { data } = this.state;
    if (!data) return null;

    const { title, fill='#000' } = this.props;
    return (
      <div style={{height: 100, overflow: 'hidden', width: '100%', position: 'relative' }}>
        <VictoryArea
          data={data}
          style={{ data: { fill: fill } }}
          //height={280}
          y='price'
          padding={0}
        />
        <Typography variant={'h2'} style={{padding: '2rem', position: 'absolute', top: 0}}>{ title }</Typography>
      </div>
      
    )
  }
}

export default withSize()(Graph);
