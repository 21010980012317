import { withStyles } from '@material-ui/styles';

import React from 'react';
import Block from './Block'
import logo from './assets/blockfront-logo.svg';

const styles = theme => (
    {
        root: {
            background: theme.palette.background.default,
            color: '#eee'
        }
    }
)

class Footer extends React.Component {
  render () {
    return (
        <div className={this.props.classes.root}>
            <Block id={'footer'} />
            <img src={logo} className="App-logo" alt="logo" style={{height: '36px', margin: '1rem'}} />
        </div>
    );
  }
}


export default withStyles(styles)(Footer)