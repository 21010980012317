import React from "react";
import { connect } from "react-redux";
import Balance from "./Balance";
import StatusFrame from "./components/StatusFrame";
import get from "lodash/get";
import { withRouter } from "react-router";
import queryString from "query-string";
import Block from "./Block";
import { makeASandwichWithSecretSauce } from "./actions/wallet";
import { Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

// TEST
// https://app.s4f3.io/sdk/quickbuy.html?appId=1234-5678&tabs=true

// const appUrl = 'https://app.safello.com';//'https://app.s4f3.io'

// PROD

let params = {
  appId: process.env.APP_ID || "330e9522-7ca6-4239-b075-3613514c9f3b",
  border: false,
  lang: "sv",
  country: "se",
  crypto: "btc",
  tabs: true,
};

/*
let params = {
    appId: process.env.APP_ID || '1234-5678',
    border: false,
    lang: 'sv',
    country: 'no',
    crypto: 'btc',
    tabs: true
}
*/

class Wallet extends React.PureComponent {
  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (get(values, "crypto", "")) {
      params = { ...params, crypto: get(values, "crypto", "") };
    }
    if (get(values, "q", "")) {
      params = { ...params, address: get(values, "q", "") };
    }
    if (get(values, "country", "")) {
      params = { ...params, country: get(values, "country", "") };
    }

    this.props.macka(params.address);
  };

  componentDidUpdate = (a, b) => {
    const current = queryString.parse(this.props.location.search);
    if (current.q !== a.address) {
      params = { ...params, address: current.q };
      this.props.macka(current.q);
    }
  };

  render() {
    //const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //let url = `${appUrl}/sdk/quickbuy.html?${queryString}`;

    const { wallet } = this.props;

    if (wallet.invalid) {
      return <div>Invalid address</div>;
    }

    return wallet.address ? (
      <div style={{}}>
        <div
          style={{
            width: "calc(90vw)",
            maxWidth: "480px",
            minWidth: "320px",
            margin: "1rem auto",
          }}
        >
          <StatusFrame>
            <Balance wallet={wallet} />
            <div style={{  }}>
              <Alert severity="warning" style={{padding: '1rem'}}>
                <AlertTitle>Tyvärr kan du för tillfället inte ladda ditt kort via denna sida.</AlertTitle>
                Under tiden vi byter leverantör behöver vi stänga av denna funktion en kortare tid.
              </Alert>
            </div>
            <div style={{ background: "green", color: "#fff", fontSize: "18px", padding: "10px" }}>
              <small>
                <span style={{ opacity: 0.6 }}>Adress:</span> {wallet.address}
              </small>
            </div>
          </StatusFrame>
        </div>
        <Block id={"intro"} />
      </div>
    ) : null;
  }
}

Wallet.propTypes = {};

const mapDispatchToProps = (dispatch, { code }) => {
  return {
    macka: (address) => {
      dispatch(makeASandwichWithSecretSauce(address));
    },
    reset: () => {
      dispatch({ type: "WALLET_RESET" });
    },
  };
};

function mapStateToProps(state, ownProps) {
  return {
    wallet: state.wallet,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
