import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Link } from "react-router-dom";
import QRCode from "qrcode";


const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const generateQR = async text => {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
    }
  };

const Qr = ({address}) => {
    const [qr, setstate] = useState(address)

    useEffect(() => {
        generateQR(address).then(res => setstate(res))
    }, [address])

    return <img src={qr} alt={qr} width={80} />
}

function Card(props) {

    return (
        <div style={{
            width: 300, 
            height: 165, 
            // background: '#222', 
            // borderRadius: '18px',
            position: 'relative',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                // margin: '1rem'
            }}>
                <div>
                <small style={{opacity: .4}}>serienummer</small><br />{ props.serial }<br />
            <small style={{opacity: .4}}>kontrollkod</small><br />{ props.code }<br />                    
                </div>
                <div>
                    <Qr address={props.address} />
                    
                </div>

            
            </div>
            <Link to={`/?q=${props.address}`}>
                <Button
                    variant={"contained"}
                    //size={"small"}
                    color={"secondary"}
                    onClick={() => {
                        window.scrollTo(0,0)
                    }}
                    fullWidth
                    >
                    Ladda kort
                    </Button>
                </Link>
        </div>
    );
}


function AddressDialog(props) {
  const classes = useStyles();
  const { onClose = () => {}, selectedValue, open, content } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog 
        onClose={handleClose} 
        aria-labelledby="card-dialog" 
        open={open}
    >
        <Card {...content[0]} />
    </Dialog>
  );
}

AddressDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default AddressDialog;
/*
export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
      <br />
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
      </Button>
      <AddressDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
*/