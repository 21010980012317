import React from "react";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import { RichText } from 'prismic-reactjs'
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withContent } from "./lib/cms";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "none",
    maxWidth: 800,
    margin: '0 auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left'
  },
  panel: {
    background: "transparent",
    //color: '#fff',
    boxShadow: "none"
  },
  details: {
    textAlign: 'left',
    flexDirection: 'column'
  }
}));

function SimpleExpansionPanel(props) {
  const classes = useStyles();

  if (!props.content) return null;

  let questions = flatMap(get(props.content, "data.body"), "primary");
  const title = props.renderText('title');

  return (
    <div className={classes.root}>
      <Typography variant={'h4'} gutterBottom style={{margin: '2rem auto'}}>{ title }</Typography>
        {questions &&
          questions.map((q, i) => 
          <ExpansionPanel key={i} className={classes.panel}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#aaa" }} />}
              aria-controls={`panel${i}a-header`}
              id={`panel${i}a-header`}
            >
              <Typography className={classes.heading}>
                {q.question[0].text}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails key={`${i}a`} className={classes.details}>
              <RichText render={q.answer} />
            </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
    </div>
  );
}

export default withContent("my.faq.uid", "deposit")(SimpleExpansionPanel);

// @withContent('hej')
