import React from 'react';
import { connect } from 'react-redux';
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import { Link } from 'react-router-dom'

import logo from '../assets/blockfront-logo.svg';
import { Typography } from '@material-ui/core';


const borderColors = {
    pending: 'rgba(100,100,100,.2)',
    success: 'rgb(0,160,0)',
    error: 'rgb(220,100,100)'
}

class StatusFrame extends React.Component {
    render() {
        const { children, wallet } = this.props;

        const status = wallet.code ? 'success' : 'error';

        const borderColor = borderColors[status];

        return (
            <div style={{
                // border: `5px solid ${borderColor}`,
                //borderRadius: '5px',
                //background: borderColor,
                display: 'flex',
                flexDirection: 'column'
            }}>
                { children }
                {wallet.code &&
                    <div style={{
                        fontSize: '16px', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        background: borderColor,
                        marginTop: 0,
                        marginBottom: 0,
                        padding: '.6rem'
                        }}>
                    <VerifiedUser />&nbsp; <Typography color={'initial'}>VERIFIED BY </Typography>
                        <Link to={'/'}><img src={logo} alt="logo" style={{height: '32px', marginLeft: '.5rem', marginTop: '5px'}} /></Link>
                    </div>
                }
            </div>    
        );
    }
}

StatusFrame.propTypes = {
    
};

function mapStateToProps(state, ownProps) {
    return {
        wallet: state.wallet
    };
}

export default connect(mapStateToProps)(StatusFrame);
