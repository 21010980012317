import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, getDoc, doc } from "firebase/firestore";

//import 'firebase/firestore'
// import 'firebase/database'

const app = initializeApp({
  apiKey: "AIzaSyBvVHPuOCgbTp2tMaP0qFPoFdp4kDXXY0o",
  authDomain: "blockfront-43327.firebaseapp.com",
  databaseURL: "https://blockfront-43327.firebaseio.com",
  projectId: "blockfront-43327",
  storageBucket: "blockfront-43327.appspot.com",
  messagingSenderId: "1082097994385",
  appId: "1:1082097994385:web:9aee6d3568748dcd",
});

//const db = firebaseApp.firestore();

const db = getFirestore(app);

export { db, collection };

export async function getCard(address) {
  const docRef = doc(db, "cards", address);
  return await getDoc(docRef);
}
