import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBalance from "@material-ui/icons/AccountBalanceWallet";
import Favorite from "@material-ui/icons/Favorite";
import { IconButton, ListSubheader } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as RouterLink  } from "react-router-dom";
import get from "lodash/get";
import { RichText } from "prismic-reactjs";
import { withContent } from "../lib/cms";
import MLink from '@material-ui/core/Link';

import storage from "../lib/storage";

const useStyles = makeStyles({
  list: {
    width: 250,
    // color: '#bbb'
  },
  fullList: {
    width: "auto"
  }
});

const Link = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
const LinkComponent = props => <MLink component={Link} {...props} />;

function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const menuItems = get(props, "content.data.nav");
  const favs = JSON.parse(storage.get("favorites")) || {};

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
        {Object.keys(favs).length > 0 && (
          <List subheader={<ListSubheader>Dina Favoriter</ListSubheader>}>
            {favs &&
              Object.keys(favs).map(address => (
                <ListItem key={address} component={LinkComponent} to={`/?q=${address}`} color={'inherit'}>
                  <ListItemIcon>
                    <Favorite />
                  </ListItemIcon>
                  <ListItemText primary={favs[address].s} />
                </ListItem>
              ))}
          </List>
        )}
      <List>
        {favs && <Divider />}
        <ListItem key={'verify'} component={LinkComponent} to={"/find"} color={'inherit'}>
          <ListItemIcon>
            <AccountBalance />
          </ListItemIcon>
          <ListItemText primary={"Verifiera kort"} />
        </ListItem>
        <Divider />
        {menuItems &&
          menuItems.map((item, i) => (
            <ListItem
              key={i}
              component={LinkComponent}
              to={item.primary.link.uid}
              color={'inherit'}
            >
              <ListItemText primary={RichText.asText(item.primary.label)} />
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>
    </div>
  );
}

export default withContent("my.navigation.uid", "main")(TemporaryDrawer);
