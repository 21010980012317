import React, { Fragment } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import queryString from "query-string";
import Wallet from "./Wallet";
import { Fade } from "react-reveal";
import Block from "./Block";
import Faq from "./Faq";
import Graph from "./Graph";
import CardBanner from './CardBanner'
import LazyLoad from 'react-lazyload';
import FindPage from "./FindPage";
import ReactGA from "react-ga";

const IndexPage = ({ match, location, history }) => {
    const values = queryString.parse(location.search);
    const address = get(values, "q");
  
    history.listen(location =>
      ReactGA.pageview(location.pathname + location.search)
    );
  
    return (
      <div>
        {address && (
          <div>
            <header className="App-header">
              <Fade>
                <Wallet address={address} />
              </Fade>
              <Graph />
            </header>
          </div>
        )}
        
        {!address && (
          <Fragment>
            <header className="App-header">
              <CardBanner />
              <FindPage />
              
              <div style={{ flex: 1 }} />              
            </header>
            <Graph fill={'#222'} />
            <div
              style={{ background: "#222", color: "#fff" }}
              className={"intro-header"}
            >
                <Block id={"about"} type={"grid"} />
            </div>
          </Fragment>
        )}
        <div style={{ margin: "1rem" }}>
        <LazyLoad>
          <Faq />
          </LazyLoad>
        </div>
      </div>
    );
  };

  export default IndexPage;