import React, { useState, useEffect } from 'react';

import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import get from 'lodash/get';

import storage from './lib/storage'

function SimpleSnackbar({message, open, handleClose, handleUndo}) { 
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{ message }</span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={handleUndo}>
              Ångra
            </Button>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }

function Favorites({address, s}) {
    const [hasFav, setFav] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
  
    function handleClick(has) {
        const favs = JSON.parse(storage.get('favorites')) || {};

        if (has) {
            setMessage('Favorit sparad');
            const newFavs = { [address]: { hasFav: 1, s: s }, ...favs }
            storage.set('favorites', JSON.stringify(newFavs))
        } else {
            delete favs[address];
            const newFavs = favs;
            storage.set('favorites', JSON.stringify(newFavs))
            setMessage('Favorit raderad');
        }

        setOpen(true)
        setFav(has);
    }
  
    function handleClose(event, reason) {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    }

    function handleUndo() {
        handleClick(!hasFav)
        setOpen(false);
    }

    useEffect(() => {
        // Update the document title using the browser API
    });

    const hasLocalFav = get((JSON.parse(storage.get('favorites')) || {} || {})[address], 'hasFav');

    const button = hasLocalFav ? (
        <Button onClick={() => handleClick(false)} color={'secondary'}>
            <Favorite  />
        </Button>
    ) : (
        <Button onClick={() => handleClick(true, )} color={'secondary'}>
            <FavoriteBorder /> 
        </Button>
    )

    return (
        <div>
            { button }
            <SimpleSnackbar message={message} open={open} handleClose={handleClose} handleUndo={handleUndo} />
        </div>
    )

}

export default Favorites;