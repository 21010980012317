import React from 'react'
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get';
import { Grid, Typography } from '@material-ui/core';

const apiEndpoint = 'https://blockfrontweb.cdn.prismic.io/api/v2'
const accessToken = 'MC5YV2t6RlJFQUFDWUEtZVdl.EgHvv73vv73vv71377-9Mu-_vR8577-977-9De-_vUzvv70o77-977-9fDVM77-9dgxw77-9We-_vRdT'

const Client = Prismic.client(apiEndpoint, { accessToken })

const styles = {
    block: {
        maxWidth: 580,
        padding: '2rem',
        textAlign: 'left',
        // margin: '0 auto'
    }
}

	
var linkResolver = function(doc) {
    // Pretty URLs for known types
    if (doc.type === 'blog') return "/post/" + doc.uid;
    if (doc.type === 'page') return "/" + doc.uid;
    // Fallback for other types, in case new custom types get created
    return "/doc/" + doc.id;
  };

export default ({id, type = ''}) => {
    const [doc, setDocData] = React.useState(null)

    React.useEffect(() => {
      const fetchData = async () => {
        const response = await Client.query(
          Prismic.Predicates.at('my.section.uid', id)
        )
        const docs = get(response, 'results[0].data.body');
        if (response && docs) {
            setDocData(docs)
        }
      }
      fetchData()
    }, [id])

    if (type === 'grid') {
        return (
            <Grid container>
                {
                    doc ? (
                        doc.map((d, i) => (
                            <Grid key={i} xs={12} sm={6} item>
                            <div style={styles.block}>
                                <Typography variant={'h4'} style={{margin: '1rem auto'}}>{RichText.asText(d.primary.title1)}</Typography>
                                <RichText render={d.primary.body1} linkResolver={linkResolver} />
                            </div>
                            </Grid>
                        ))
                    ) : null
                }
            </Grid>
        )
    }

    return (
        <React.Fragment>
            {
                doc ? (
                    doc.map((d, i) => (
                        <div key={i} style={styles.block}>
                            <Typography variant={'h4'}>{RichText.asText(d.primary.title1)}</Typography>
                            <RichText render={d.primary.body1} linkResolver={linkResolver} />
                        </div>
                    ))
                ) : null
            }
        </React.Fragment>
    )

};