import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom'

import logo from './assets/blockfront-logo.svg';
import ResponsiveDrawer from './components/ResponsiveDrawer'
import { AppBar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    // marginBottom: '2rem'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    textAlign: 'center'
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar style={{background: 'none',  boxShadow: 'none'}} position={'static'}>
      <Toolbar color={'primary'}>
          <div className={classes.title}>
            <Link to={'/'} style={{padding: '1rem', display: 'inline-block'}}>
              <img src={logo} className="App-logo" alt="logo" style={{height: '36px', marginTop: '6px'}} />
              </Link>
          </div>
          <div style={{flex: 1}}></div>
          <ResponsiveDrawer />
          </Toolbar>
      </AppBar>
          
      
    </div>
  );
}