import React from 'react'
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get';
import { Typography } from '@material-ui/core';

const apiEndpoint = 'https://blockfrontweb.cdn.prismic.io/api/v2'
const accessToken = 'MC5YV2t6RlJFQUFDWUEtZVdl.EgHvv73vv73vv71377-9Mu-_vR8577-977-9De-_vUzvv70o77-977-9fDVM77-9dgxw77-9We-_vRdT'

const Client = Prismic.client(apiEndpoint, { accessToken })

const styles = {
    cmspage: {
        width: 600,
        maxWidth: '100vw',
        padding: '2rem',
        textAlign: 'left',
        margin: '0 auto'
    }
}

export default ({id, type = '', match = {}}) => {
    const [doc, setDocData] = React.useState(null)


    const gid = id || match.params.id;

    React.useEffect(() => {
      const fetchData = async () => {
        const response = await Client.query(
          Prismic.Predicates.at('my.page.uid', gid)
        )
        const docs = get(response, 'results[0]');
        if (docs) {
            setDocData(docs.data)
        }
      }
      fetchData()
    }, [gid, id])

    if (!doc) {
        return null;
    }

    return (
        <article style={styles.cmspage}>
            <Typography variant={'h2'}>{RichText.asText(doc.title)}</Typography>
            <RichText render={doc.body} />
        </article>
    )

};