import React from 'react'

import {
    Input,
    FormControl,
    InputLabel,
    FormHelperText,
    Typography,
    Grid,
    Paper,
    Button
} from '@material-ui/core';

import SwishLogo from '../../assets/swish_logo_primary.svg'

const styles = {
    root: {
        maxWidth: 500,
        margin: '0 auto'
    }
}

const fields = [
    {
        id: 'name',
        description: '',
        label: 'Fullständigt namn',
        size: 12
    }, {
        id: 'address',
        description: '',
        label: 'Gatuadress',
        size: 12
    }, {
        id: 'zip',
        description: '',
        label: 'Postnummer',
        size: 6
    }, {
        id: 'city',
        description: '',
        label: 'Stad',
        size: 6
    }
]

const OrderForm = () => (
    
        <header className="App-header">
            <div style={styles.root}>
            <Typography variant={'h2'}>
                Köp värdebärare
            </Typography>
            <Paper style={{padding: '1rem', margin: '1rem'}}>
                <form>
                    <Grid container>
                        {fields.map(f => (
                            <Grid key={f.id} xs={f.size}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor={f.id}>{f.label}</InputLabel>
                                    <Input id={f.id} aria-describedby={f.id + 'helper-text'}/>
                                    <FormHelperText id={f.id + 'helper-text'}>{f.description}</FormHelperText>
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </form>
                <img src={SwishLogo} alt={'swish'} style={{width: 50, margin: '.5rem'}} />
            </Paper>
                <Button variant={'contained'} size={'large'}> Bekräfta och betala</Button>
            </div>
            
        </header>
        
    
)

const OrderPage = () => (<OrderForm/>);

export default OrderForm;

export {OrderPage}