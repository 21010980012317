import { getCard } from "../firebase";
import { exchangeAction } from "./exchange";
import bitcoin from "bitcoin-units";

export const getBalance = (b) => (dispatch, a) => {
  const url = `https://blockchain.info/q/addressbalance/${b}`;

  return fetch(url)
    .then((response) => response.json())
    .then((res) => {
      const balance = bitcoin(res, "satoshi").to("BTC").toString();
      dispatch({ type: "WALLET_ACTION", payload: { balance } });
    });
};

export const walletUpdate = (b) => (dispatch, a) => {
  dispatch({
    type: "WALLET_UPDATE",
    payload: "ACTION PLEASE",
  });
};

export const validateAddress = (b) => (dispatch, a) => {};

export function makeASandwichWithSecretSauce(address) {
  return (dispatch) => {
    getCard(address).then((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        dispatch(exchangeAction());
        dispatch(getBalance(address));
        dispatch({ type: "WALLET_ACTION", payload: data });
      } else {
        dispatch({ type: "WALLET_ACTION", payload: { invalid: true } });
      }
    });
  };
}
