import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from 'prismic-reactjs'
import get from "lodash/get";

const apiEndpoint = "https://blockfrontweb.cdn.prismic.io/api/v2";
const accessToken =
  "MC5YV2t6RlJFQUFDWUEtZVdl.EgHvv73vv73vv71377-9Mu-_vR8577-977-9De-_vUzvv70o77-977-9fDVM77-9dgxw77-9We-_vRdT";

const Client = Prismic.client(apiEndpoint, { accessToken });

const withContent = (...args) => (WrappedComponent, selectData) => {
  return class extends React.Component {
    state = {
      content: null
    };

    componentDidMount = () => {
      this.fetchData();
    };

    fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at(args[0], args[1])
      );
      const docs = get(response, "results[0]");

      if (docs) {
        this.setState({ content: docs });
      }
    };

    renderContent = (a) => {
        return RichText.render(this.state.content.data[a])
    };

    renderText = (a) => {
        return RichText.asText(this.state.content.data[a])
    };

    render() {
      return (
        <WrappedComponent
          content={this.state.content}
          renderContent={this.renderContent}
          renderText={this.renderText}
          {...this.props}
        />
      );
    }
  };
};

export default withContent;
