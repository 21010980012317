import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import App from '../App'

// #f0bc10 yellow
const defaultTheme = {
  palette: {
    type: 'dark',
    background: {
      default: '#192940',
      paper: 'rgb(0, 24, 39)'
    },
    primary: { 
      main: '#00254b',
      // contrastText: "white"
    },
    secondary: { main: '#f0bc10' },
    text: {
      // primary: '#fff'
    },
  },
  typography: {
    // fontFamily: '"Montserrat", sans-serif',
    h2: {
      fontWeight: 700
    },
    h3: {
      fontWeight: 700
    },
    h4: {
      fontWeight: 600
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        //fontFamily: '"Buenos Aires"'
        padding: '10px 40px 14px 16px',
      }
    }
  }
}

let theme = createTheme(defaultTheme);
theme = responsiveFontSizes(theme);

// <Route exact path="/:code?" component={App} />

const Root = ({ store }) => (
  <MuiThemeProvider theme={theme}>
  <Provider store={store}>
    <App />
  </Provider>
  </MuiThemeProvider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root